/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================

import TweenLite from 'gsap';


function jhSite() {

  function init() {
    imgpreload(["assets/images/lines.png", "assets/images/waves.jpg"], function( images ) {
      openAnimation();
    });

  };

  function imgpreload( imgs, callback ) {
    var loaded = 0;
    var images = [];
    imgs = Object.prototype.toString.apply( imgs ) === '[object Array]' ? imgs : [imgs];
    var inc = function() {
      loaded += 1;
      if ( loaded === imgs.length && callback ) {
        callback( images );
      }
    };
    for ( var i = 0; i < imgs.length; i++ ) {
      images[i] = new Image();
      images[i].onabort = inc;
      images[i].onerror = inc;
      images[i].onload = inc;
      images[i].src = imgs[i];
    }
  }

  function iD(id) {
    return document.getElementById(id);
  };

  function openAnimation() {
    var logoBk = iD('jh-logo-bk'),
        logoBorder = iD('jh-logo-border'),
        logoSiderect = iD('jh-logo-siderect'),
        logoTitle = iD('jh-logo-h1'),
        description = iD('jh-logo-description'),
        leftBK = iD('jh-left-bk'),
        rightBK = iD('jh-right-bk');
    iD('site-wrapper').removeAttribute('style');
    TweenLite.fromTo(leftBK, 2, {opacity:0}, {opacity:1});
    TweenLite.fromTo(rightBK, 2, {opacity:0}, {opacity:1});
    TweenLite.fromTo(logoBorder, 1.2, {opacity:0, x:'10%', scaleX:.98, scaleY:.98}, {opacity:1, rotationX:0, rotationY:0, x:'0%', scaleX:1.0, scaleY:1.0});
    TweenLite.fromTo(logoSiderect, 1.2, {opacity:0, x:'10%', y:'-3%'}, {opacity:1, x:'0%', y:'0%'});
    TweenLite.fromTo(logoTitle, 1, {opacity:0}, {opacity:1, delay:1.0});
    TweenLite.fromTo(logoBk, 1.4, {opacity:0}, {opacity:1, delay:.6});

    TweenLite.fromTo(description, 1.8, {opacity:0}, {opacity:1, delay:1.2});
  };

  init();
};

jhSite();
